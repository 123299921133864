body {
  font-family: 'SpoqaHanSansNeo', 'Montserrat';
}

@font-face {
  font-family: 'SpoqaHanSansNeo';
  font-style: thin;
  font-weight: 300;
  font-display: swap;
  src: url('/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.ttf') format('truetype');
  unicode-range: U+AC00-D7AF;
}

@font-face {
  font-family: 'SpoqaHanSansNeo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.ttf') format('truetype');
  unicode-range: U+AC00-D7AF;
}
@font-face {
  font-family: 'SpoqaHanSansNeo';
  font-style: medium;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.ttf') format('truetype');
  unicode-range: U+AC00-D7AF;
}

@font-face {
  font-family: 'SpoqaHanSansNeo';
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.ttf') format('truetype');
  unicode-range: U+AC00-D7AF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: thin;
  font-weight: 300;
  font-display: swap;
  src: url('/fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
  unicode-range:U+0020-007E;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  unicode-range:U+0020-007E;
}
/* 제플린 기준 normal 이 light 임 */
/* 2.15일 제플린 기준 normal - normal 로 수정 */

@font-face {
  font-family: 'Montserrat';
  font-style: medium;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  unicode-range:U+0020-007E;
}

@font-face {
  font-family: 'Montserrat';
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  unicode-range:U+0020-007E;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

* {
  box-sizing: border-box;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  /*content: '';*/
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: #0070f3;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  /* antd image preview시 display: block일 경우 스타일 좌측으로 밀림. 유효한 스타일인지확인 필요 */
  display: block;
}

input[type=file] {
  visibility: hidden;
  position: absolute;
}
input::placeholder {
  font-size: 14px;
}

/* Antd Editable Table */
.ant-table-cell > .ant-row .ant-form-item {
  margin-bottom: 0px;
}

.ant-table-tbody > tr > td {
  vertical-align: middle;
}

/* Antd TimePicker */
.shipping-dead-line-timepicker .ant-picker-time-panel-column::after {
  height: 0;
}

/* Quill Editor */
.ql-editor{
  min-height: 250px !important;
  max-height: 900px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

/* to use in MSIngleImage */
.ant-image-preview-img {
  display: inline;
}
